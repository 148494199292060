import React from "react"
import {graphql} from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import Grid from "../components/grid/grid";
import styled from "styled-components"

type IndexProps = {
  data: any
}

const getAspectRatio = (props: any, index: number) => `${100 / props.fluid[index].aspectRatio}% !important;`

const StyledImage = styled(Img)`
> div:first-child {
  @media (max-width: 960px) {
    padding-bottom: ${props => getAspectRatio(props, 0)}
  }
  @media (min-width: 960px) {
    padding-bottom: ${props => getAspectRatio(props, 1)}
  }
`

const IndexPage = ({data}: IndexProps) => {
  const dtImage = data.allContentfulPage.nodes[0].images?.[1]?.fluid
  const mbImage = data.allContentfulPage.nodes[0].images?.[0]?.fluid
  const sources = [
    mbImage,
    {
      ...dtImage,
      media: `(min-width: 960px)`
    }
  ]
  return (
    <Layout>
      <SEO title="Home"/>
      <Grid>
        <StyledImage fluid={sources}  loading="eager" fadeIn={false} />
      </Grid>
    </Layout>
  )
}

export const query = graphql`
  {
    allContentfulPage(filter: {name: {eq: "home"}}) {
      nodes {
        images {
          fluid(sizes: "(min-width: 36em), 40vw, 100vw", quality: 90) {
              ...GatsbyContentfulFluid_withWebp
          }
        }
      }
  }
}
`

export default IndexPage
